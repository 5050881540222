@import '../../../theme/global-variables.scss';

.image_slider {
  position: relative;
  height: 600px;
  margin: 30px auto;
  max-width: $max-width;
  z-index: -1;

  .slider_bg {
    // background-image: url('../../../assets/about2.jpg');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    filter: blur(5px);
    -webkit-filter: blur(5px);
    height: 100%;
  }

  .slider {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 100%;
    z-index: 2;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    .slide {
      width: 70%;
      height: 80%;
      display: none;
      box-sizing: border-box;

      &.active {
        display: block;
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 10px;
        border: 2px solid white;
      }

      .caption {
        display: none;
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        background-color: rgba(0, 0, 0, 0.5);
        color: #fff;
        font-size: 18px;
      }
    }

    .controls {
      position: absolute;
      display: flex;
      justify-content: space-between;
      width: 80%;
  
      button {
        background-color: white;
        border-radius: 50%;
        border: 1px solid #333;
        color: #333;
        font-size: 18px;
        width: 40px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
  
        &:hover {
          background-color: #333;
          color: #fff;
        }
      }
    }

    .dot_indicator {
      display: flex;
      justify-content: center;
      margin-top: 20px;
    }
    
    .dot {
      width: 10px;
      height: 10px;
      border-radius: 50%;
      background-color: #ccc;
      margin: 0 5px;
      cursor: pointer;
    }
    
    .dot.active {
      background-color: #f37e21;
    }
  }

  .preview {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100px;
    display: flex;
    justify-content: space-between;
    padding: 10px;
    display: none;

    .preview_slide {
      width: 20%;
      height: 100%;
      margin: 10px;
      cursor: pointer;

      &.active {
        border: 2px solid #fff;
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
}