@import "../../theme/global-variables.scss";

.contact-container {
    max-width: $max-width;
    margin: 0 auto;

    .contact-wrapper {

        .location {
            position: relative;
            top: 6px;

            img {
                width: 100%;
            }
        }

        .contact-us-content {
            display: flex;
            align-items: flex-start;
            justify-content: space-between;
            padding: 6rem;
            text-align: left;

            .heading {
                margin-bottom: 20px;

                h3 {
                    font-size: 1.6rem;
                    color: #f37e21;
                    margin-bottom: 10px;
                }
            }

            .contact-details {
                width: 45%;
                word-spacing: 2px;

                .contact-cards {
                    border-radius: 4px;
                    display: flex;
                    align-items: center;
                    margin-top: 20px;
                    width: fit-content;
                    padding: 15px;
                    background-color: rgb(244, 242, 242);
                    width: 100%;
                    box-sizing: border-box;

                    .contact-icon {
                        background-color: #f37e21;
                        border-radius: 50%;
                        color: white;
                        display: flex;
                        padding: 5px;
                        justify-content: center;
                        align-items: center;
                        height: 20px;
                        width: 20px;
                        padding: 15px;
                        margin-right: 10px;

                        img {
                            width: 50px;
                            height: 50px;
                        }
                    }

                    .contact-type-details {

                        .title {
                            color: #f37e21;
                            font-weight: 600;
                            font-size: 1.2rem;
                            line-height: 23px;
                            margin-top: 10px;
                            margin-bottom: 20px;
                        }

                        p {
                            margin-top: 10px;

                            a{
                                color: black;
                                text-decoration: none;
                            }
                        }
                    }
                }
            }

            .contact-us-form {
                width: 50%;

                .user-form {
                    display: flex;
                    flex-direction: column;

                    textarea {
                        resize: none;
                        max-width: 100%;
                    }

                    .input-field {
                        margin-bottom: 20px;
                        padding: 15px;
                        border-radius: 4px;
                        width: 100%;
                        background-color: rgb(244, 242, 242);
                        border: none;
                        resize: none;
                        box-sizing: border-box;
                    }

                    .button {
                        padding: 15px;
                        color: white;
                        cursor: pointer;
                        border: none;
                        background-color: #f37e21;
                        border-radius: 25px;
                        font-weight: 600;
                        letter-spacing: 1px;
                        text-transform: uppercase;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 944px) {

    .contact-container {

        .contact-wrapper {

            .contact-us-content {
                padding: 80px 40px;

                .heading {
                    h3 {
                        font-size: 1.6rem;
                    }

                    p {
                        font-size: 1.1rem;
                    }
                }

                .contact-details {
                    width: 50%;

                    .contact-cards {
                        padding: 20px;

                        .contact-icon {

                            img {
                                width: 50px;
                                height: 50px;
                            }
                        }

                        .contact-type-details {
                            .title {
                                font-size: 18px;
                            }

                            p {
                                font-size: 1rem;
                            }
                        }
                    }
                }

                .contact-us-form {
                    width: 46%;

                    .user-form {
                        padding: 0px;

                        textarea {
                            resize: none;
                            max-width: 100%;
                        }

                        .input-field {
                            margin: 0px 0px 15px 0px;
                            padding: 15px;
                            font-size: 1rem;
                        }

                        .button {
                            padding: 15px;
                        }
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 710px) {
    .contact-container {

        .contact-wrapper {

            .contact-us-content {
                padding: 40px;
                flex-direction: column;

                .heading {
                    text-align: center;
                    width: 100%;
                }

                .contact-details {
                    width: 100%;

                    .contact-cards {

                        .contact-icon {

                            img {
                                width: 50px;
                                height: 50px;
                            }
                        }
                    }
                }

                .contact-us-form {
                    width: 100%;
                    margin-top: 30px;

                    .user-form {

                        .button {
                            width: 100%;
                        }
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 426px) {

    //mobile-view
    .contact-container {

        .contact-wrapper {

            .contact-us-content {
                padding: 40px;
                flex-direction: column;

                .heading {
                    h3 {
                        font-size: 1.2rem;
                    }

                    p {
                        font-size: 1rem;
                    }
                }

                .contact-details {
                    width: 100%;

                    .contact-cards {
                        padding: 15px;

                        .contact-icon {

                            img {
                                width: 30px;
                                height: 30px;
                            }
                        }

                        .contact-type-details {
                            .title {
                                font-size: 15px;
                            }

                            p {
                                font-size: 0.9rem;
                            }
                        }
                    }
                }
            }
        }
    }
}