@import '../../../theme/global-variables.scss';

.footer-container {
    background-image: url('../../../assets/footer/footer-bg.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    background-color: #232323;
    max-width: $max-width;
    margin: 0 auto;
    position: relative;

    .footer-wrapper {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        flex-wrap: wrap;
        padding: 3rem 4rem;

        .mask {
            background: rgb(255, 255, 255);
            background: linear-gradient(180deg, rgba(255, 255, 255, 0.7318277652858018) 0%, rgba(35, 35, 35, 1) 15%);
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
        }

        .call-schedule {
            color: #fff;
            font-size: 2rem;
            font-weight: 500;
            text-align: center;
            z-index: 1;

            .contact {

                a {
                    color: #f37e21;
                    text-decoration: none;
                }
            }

            .button-container {
                margin: 20px 0;

                .contact-button {
                    background-color: #f37e21;
                    border-radius: 30px;
                    border: none;
                    font-size: 0.8rem;
                    color: #fff;
                    cursor: pointer;
                    font-weight: 600;
                    letter-spacing: 1px;
                    text-transform: uppercase;
                    width: 150px;
                    padding: 15px 30px;
                    text-decoration: none;
                }
            }
        }

        .footer-contents {
            margin-top: 20px;
            display: flex;
            justify-content: space-around;
            flex-wrap: wrap;
        }

        .contact-details {
            color: #fff;
            z-index: 1;

            .logo-container {
                width: 250px;

                img {
                    width: 100%;
                }
            }

            address,
            .email,
            .mobile,
            .person {
                margin: 15px 0;
            }

            .email,
            .mobile {
                display: flex;
                align-items: center;

                span {
                    color: #f37e21;
                    display: flex;
                    align-items: center;
                    padding-right: 10px;
                }

                a {
                    color: white;
                    text-decoration: none;
                }
            }
        }

        .follow-us,
        .quick-links {
            color: #fff;
            z-index: 1;

            label {
                font-size: 1.4rem;
                font-weight: 500;
                border-bottom: 1px solid #f37e21;
            }

            ul {

                li {
                    list-style-type: none;
                    margin: 15px 0;

                    a {
                        display: flex;
                        justify-content: flex-start;
                        align-items: center;
                        color: white;
                        text-decoration: none;

                        .social-icon {
                            background-color: #f37e21;
                            border-radius: 50%;
                            display: flex;
                            padding: 5px;
                            justify-content: center;
                            align-items: center;
                            height: 20px;
                            width: 20px;
                            padding: 5px;
                            margin-right: 10px;
                        }
                    }
                }

                .enquiry_contact {

                    .enquiry {

                        .email,
                        .mobile {
                            display: flex;
                            align-items: center;
                            font-size: 0.8rem;
                            margin: 10px 0;

                            span {
                                color: #f37e21;
                                display: flex;
                                align-items: center;
                                padding-right: 10px;
                            }

                            a {
                                color: white;
                                text-decoration: none;
                            }
                        }
                    }
                }
            }
        }
    }

    .copyright-container {
        border-top: 0.5px solid lightgray;
        padding: 20px;
        position: relative;
        margin: 0 3rem;
        z-index: 1;

        .copyright-wrapper {
            color: white;
            text-align: center;

            p {

                a {
                    color: #f37e21;
                    text-decoration: none;
                }
            }
        }
    }
}

@media screen and (max-width: 1024px) {
    .footer-container {

        .footer-wrapper {
            padding: 60px;

            .footer-contents {
                justify-content: space-between;
            }
        }
    }
}

@media screen and (max-width: 768px) {
    .footer-container {

        .footer-wrapper {
            padding: 20px 50px;

            .contact-details {

                .logo-container {
                    width: 225px;
                }
            }

            .mask {
                background: linear-gradient(180deg, rgba(255, 255, 255, 0.7318277652858018) 0%, rgba(35, 35, 35, 1) 18%);
            }

            .call-schedule {
                font-size: 1.6rem;

                .contact-button {
                    font-weight: 400;
                }
            }

            .footer-contents {
                flex-direction: column;

                .contact-details,
                .quick-links {
                    margin-bottom: 20px;
                }
            }
        }
    }
}

@media screen and (max-width: 546px) {

    .footer-container {

        .footer-wrapper {

            .call-schedule {
                font-size: 1.2rem;

                .contact-button {
                    font-size: 0.8rem;
                    font-weight: 500;
                    margin: 10px 0;
                    width: 120px;
                    padding: 10px;
                }
            }
        }

        .copyright-container {

            .copyright-wrapper {
                font-size: 0.8rem
            }
        }
    }
}

@media screen and (max-width: 496px) {
    .footer-container {

        .footer-wrapper {

            .contact-details {

                .logo-container {
                    width: 200px;
                }
            }

            .footer-contents {
                font-size: 0.9rem;

                .contact-details,
                .quick-links,
                .follow-us {

                    label {
                        font-size: 1rem;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 425px) {

    .footer-container {

        .footer-wrapper {
            padding: 20px 30px;
        }

        .copyright-container {
            padding: 20px 0;

            .copyright-wrapper {
                font-size: 0.7rem
            }
        }
    }
}