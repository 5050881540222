@import '../../../theme/global-variables.scss';

nav{
    position: absolute;
    top: 0;
    max-width: $max-width;
    left: 50%;
    transform: translate(-50%, 0);
    width: 100%;
    // height: 100%;
    z-index: 1;

    .nav-wrapper{
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 35px 50px;

        .logo{
            width: 180px;

            img{
                width: 100%;
                object-fit: contain;
            }
        }

        .menu{
            display: none;
        }

        ul{
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 50%;

            li{
                list-style-type: none;

                a{
                    color: white;
                    cursor: pointer;
                    font-size: 1.25rem;
                    letter-spacing: 1px;
                    text-decoration: none;
                }
            }
        }
    }
}

@media screen and (max-width: 1100px) {

    nav{
    
        .nav-wrapper{

            .logo{
                width: 150px;
            }
    
            ul{
                width: 60%;

                li{
    
                    a{
                        font-size: 1rem;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 768px) {

    nav{
    
        .nav-wrapper{
    
            ul{
                display: none;
            }

            .menu{
                display: block;
            }
        }
    }
}

@media screen and (max-width: 426px) {

    nav{
    
        .nav-wrapper{
            padding: 35px;
        }
    }
}