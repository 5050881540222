@import "../../theme/global-variables.scss";

.header {
    max-width: $max-width;
    margin: 0 auto;
    width: 100%;
    height: 100vh;
    position: relative;
    // clip-path: polygon(50% 0%, 100% 0, 100% 95%, 75% 100%, 40% 96%, 0 100%, 0 0);

    .overlay {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba($color: #000000, $alpha: 0.4);
    }

    video {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    .contents {
        max-width: 1600px;
        color: #fff;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        text-align: center;
        width: 70%;

        h3 {
            font-size: 1.2rem;
            letter-spacing: 1px;
        }

        h1 {
            font-size: 5.5rem;
            margin: 30px 0;
        }

        .tagline {
            margin-bottom: 30px;
            font-weight: 500;
            font-size: 1.2rem;
            text-transform: capitalize;
        }

        .btn {
            border: none;
            outline: none !important;
            background-color: #f37e21;
            color: #ffffff;
            font-size: 18px;
            font-weight: 600;
            text-transform: uppercase;
            padding: 20px 45px;
            border-radius: 40px;
            -webkit-transition: all 0.3s linear;
            transition: all 0.3s linear;
            letter-spacing: 0.2em;
            cursor: pointer;
        }
    }
}

@media screen and (max-width: 1480px) {

    .header {

        .contents {

            h1 {
                font-size: 3.9rem;
            }
        }
    }
}

@media screen and (max-width: 1024px) {
    .header {

        .contents {

            h1 {
                font-size: 3.5rem;
            }
        }
    }
}

@media screen and (max-width: 909px) {
    .header {

        .contents {

            h1 {
                font-size: 2.5rem;
            }
        }
    }
}

@media screen and (max-width: 650px) {
    .header {
        // height: 80vh;

        .contents {

            h1 {
                font-size: 2rem;
            }
        }
    }
}

@media screen and (max-width: 530px) {
    .header {

        .contents {

            h1 {
                font-size: 2rem;
            }

            .tagline {
                font-size: 1rem;
            }

            .btn {
                font-size: 13px;
                padding: 20px;
            }
        }
    }
}