.sidenav {
  box-sizing: border-box;
  // height: 100%;
  height: 100vh;
  width: 0;
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  background-color: #232323;
  overflow-x: hidden;
  transition: 0.5s;

  .closebtn {
    color: white;
    text-decoration: none;
  }

  .logo-container {
    margin-top: 40px;
    width: 70%;

    img {
      width: 100%;
    }
  }

  .nav_items {

    div {
      margin: 20px 0;

      a {
        color: white;
        font-size: 1.4rem;
        margin: 20px 0;
        text-decoration: none;
        transition: 0.3s;
      }
    }
  }

  .follow-us {
    color: #fff;
    z-index: 1;
    text-align: center;
    position: absolute;
    bottom: 80px;
    left: 50%;
    transform: translate(-50%, -10px);

    label {
      font-size: 1.2rem;
      font-weight: 500;
      border-bottom: 1px solid #f37e21;
    }

    ul {
      display: flex;
      justify-content: unset;
      width: 100%;


      li {
        list-style-type: none;
        margin: 15px 0;

        a {
          display: flex;
          justify-content: flex-start;
          align-items: center;

          .social-icon {
            background-color: #f37e21;
            border-radius: 50%;
            display: flex;
            padding: 5px;
            justify-content: center;
            align-items: center;
            height: 20px;
            width: 20px;
            padding: 5px;
            margin-right: 10px;
          }
        }
      }
    }
  }
}

.sidenav a:hover {
  color: #f1f1f1;
}

.sidenav .closebtn {
  background: transparent;
  border: none;
  position: absolute;
  top: 2px;
  right: 15px;
  font-size: 36px;
  margin-left: 50px;
}

@media screen and (max-width: 768px) {

  .sidenav {

    .logo-container {
      width: 50%;
    }
  }
}

@media screen and (max-width: 425px) {

  .sidenav {

    .logo-container {
      width: 70%;
    }
  }
}

@media screen and (max-height: 450px) {
  .sidenav {
    padding-top: 15px;
  }

  .sidenav a {
    font-size: 18px;
  }
}