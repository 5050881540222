@import "../../theme/global-variables.scss";

.catalogue_container {
    max-width: $max-width;
    margin: 0 auto;
    background-color: #F4F4F4;

    .catalogue_wrapper {
        color: #232323;
        padding: 80px 150px;
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        justify-content: space-between;
        gap: 20px;

        .content {
            width: 50%;

            .title {
                font-size: 2.5rem;
                font-weight: 400;
            }

            .message {
                line-height: 25px;
                margin: 20px 0;
            }
        }

        .btn {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding-left: 20px;
            border: none;
            outline: none !important;
            background-color: #f37201;
            color: #ffffff;
            font-size: 14px;
            font-weight: 600;
            text-transform: capitalize;
            padding: 15px 20px;
            border-radius: 40px;
            margin-top: 20px;
            -webkit-transition: all 0.3s linear;
            transition: all 0.3s linear;
            letter-spacing: 0.1em;
            cursor: pointer;

            svg {
                padding-left: 6px;
            }
        }

        .catalogue {
            width: 400px;
            height: 250px;
            border-radius: 10px;
        }
    }
}

@media screen and (max-width: 1150px) {

    .catalogue_container {

        .catalogue_wrapper {

            .content {
                width: 60%;

                .title {
                    font-size: 2rem;
                }
            }

            .catalogue {
                width: 300px;
            }
        }
    }
}

@media screen and (max-width: 1100px) {

    .catalogue_container {

        .catalogue_wrapper {

            .content {
                width: 100%;

                .title {
                    font-size: 2rem;
                }
            }

            .catalogue {
                order: -1;
                width: 100%;
            }
        }
    }
}

@media screen and (max-width: 845px) {

    .catalogue_container {

        .catalogue_wrapper {
            padding: 40px 80px;
        }
    }
}

@media screen and (max-width: 645px) {

    .catalogue_container {

        .catalogue_wrapper {
            
            .content {

                .title {
                    font-size: 1.8rem;
                }
            }
        }
    }
}

@media screen and (max-width: 426px) {

    .catalogue_container {

        .catalogue_wrapper {
            padding: 40px 50px;

            .content {

                .title {
                    font-size: 1.6rem;
                }
            }
        }
    }
}