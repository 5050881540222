@import "../../../theme/global-variables.scss";

.product_container{
    max-width: $max-width;
    margin: 0 auto;
    text-align: center;

    h2 {
        color: #f37e21;
        font-size: 3rem;
    }

    .product_wrapper{
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
        padding: 80px 150px;

        .product{
            border-radius: 50%;
            position: relative;
            margin: 20px;
            width: 280px;
            height: 280px;

            img{
                border-radius: 50%;
                height: 100%;
                width: 100%;
                object-fit: cover;
            }

            p{
                background-color: rgba(0, 0, 0, 0.6);
                border-radius: 50%;
                color: white;
                font-size: 1.2rem;
                font-weight: 600;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                width: 100%;
                display: flex;
                height: 100%;
                align-items: center;
                justify-content: center;
            } 
        }
    }
}

@media screen and (max-width: 630px) {
    
    .product_container {

        h2{
            font-size: 2.4rem;
        }

        .product_wrapper{
            padding: unset;
        }
    }
}

@media screen and (max-width: 496px) {
    
    .product_container {

        h2{
            font-size: 1.5rem;
        }
    }
}