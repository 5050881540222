@import "../../theme/global-variables.scss";

.catalogue-container {
    max-width: $max-width;
    margin: 0 auto;

    .catalogue-wrapper {

        .catalogues {
            display: flex;
            align-items: center;
            justify-content: space-between;
            flex-wrap: wrap;
            padding: 0px 150px;

            .catalogue {
                background-color: #F4F4F4;
                border-radius: 4px;
                cursor: pointer;
                margin: 30px 20px;
                text-align: center;
                font-weight: bold;
                width: 300px;
                height: 300px;
                position: relative;
                box-shadow: 1px 1px 4px grey;
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;

                .catalogue-image{
                    border-radius: 50%;
                    width: 200px;
                    height: 200px;
                    border: 1px solid grey;

                    img {
                        border-radius: 50%;
                        opacity: 1;
                        display: block;
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                        transition: .5s ease;
                        backface-visibility: hidden;
                    }
                }

                .download_button {
                    background-color: #f37e21;
                    border-radius: 25px;
                    border: none;
                    color: white;
                    padding: 8px 9px;
                    text-transform: uppercase;
                    transition: 0.5s ease;
                    position: absolute;
                    top: 28px;
                    right: 0px;
                    transform: translate(-50%, -50%);
                    -ms-transform: translate(-50%, -50%);
                }

                .title{
                    margin-top: 20px;
                    letter-spacing: 1px;
                    text-transform: uppercase;
                }
            }

            .catalogue:hover .image {
                opacity: 0.3;
            }

            // .catalogue:hover .download_button {
            //     opacity: 1;
            // }
        }
    }
}

@media screen and (max-width: 980px) {
    .catalogue-container {

        .catalogue-wrapper {

            .catalogues {
                justify-content: center;
                padding: 50px;
            } 
        }
    }
}

@media screen and (max-width: 440px) {
    .catalogue-container {

        .catalogue-wrapper {

            .catalogues {
                padding: 0 0 20px;
            } 
        }
    }
}