@import '../../../theme/global-variables.scss';

.vincent-deals-container {
    max-width: $max-width;
    margin: 0 auto;

    .vincent-deals-wrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 80px 150px;

        .image-wrapper {
            position: relative;
            width: 40%;

            img {
                width: 100%;
            }

            .fg-image {
                background-color: #fff;
                padding: 20px;
                position: absolute;
                bottom: 30%;
                left: 25%;
                width: 80%;
            }
        }



        .about-contents {
            width: 50%;
            text-align: left;
            .images {
                display: none;
            }
            h2 {
                color: #f37e21;
                font-size: 3rem;
            }

            .detail {
                color: #5e5d5d;
                font-size: 1.2rem;
                line-height: 40px;
            }

            h3 {
                color: #f37e21;
                font-size: 2rem;
            }

            ul {

                li {
                    background-color: #f7f2f2;
                    border-radius: 4px;
                    padding: 20px;
                    font-size: 1.5rem;
                    list-style-type: none;
                    margin: 20px 0;
                }
            }
        }
    }
}

@media screen and (max-width: 1220px) {

    .vincent-deals-container {
    
        .vincent-deals-wrapper {
            padding: 80px 100px;
        }
    }
}
@media screen and (max-width: 1024px) {
    .vincent-deals-container {

        .vincent-deals-wrapper {
            padding: 40px 100px;
            flex-direction: column;

            .image-wrapper {
                display: none;
            }

            .about-contents {
                width: 100%;
                h2 {
                    font-size: 3rem;
                }
                .images {
                    display: block;
                    width: 100%;

                    img {
                        width: 100%;
                    }

                    .bg-image{
                        margin-bottom: 10px;
                    }

                    .fg-image{
                        display: none;
                    }
                }
                .divider {
                    margin: 25px 0;
                }
                .detail {
                    font-size: 1.3rem;
                }
            }
        }
    }
}

@media screen and (max-width: 821px) {
    .vincent-deals-container {

        .vincent-deals-wrapper {
            padding: 30px 50px;
            flex-direction: column;

            .image-wrapper {
                display: none;
            }

            .about-contents {
                width: 100%;
                h2 {
                    font-size: 2.5rem;
                }

                .detail {
                    font-size: 1.1rem;
                }

                h3 {
                    font-size: 2rem;
                }

                ul {

                    li {
                        font-size: 1.2rem;
                        padding: 15px;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 496px) {
    .vincent-deals-container {

        .vincent-deals-wrapper {
            padding: 20px 35px;
            .about-contents {

                h2 {
                    font-size: 1.5rem;
                }
                .divider {
                    margin: 20px 0;
                }
                .detail {
                    font-size: 1rem;
                }

                h3 {
                    font-size: 1.5rem;
                }

                ul {

                    li {
                        font-size: 1rem;
                    }
                }
            }
        }
    }
}