/* @import url('https://fonts.googleapis.com/css2?family=Exo:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,600;1,700;1,900&display=swap'); */

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'Exo', sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

*{
  margin: 0;
  padding: 0;
}

p, h1, h2, h3, h4, h5, h6{
  margin: 0;
  padding: 0;
}

.lineUp {
  animation: 2s anim-lineUp ease-out;
}

@keyframes anim-lineUp {
  0% {
      opacity: 0;
      transform: translateY(80%);
  }

  20% {
      opacity: 0;
  }

  50% {
      opacity: 1;
      transform: translateY(0%);
  }

  100% {
      opacity: 1;
      transform: translateY(0%);
  }
}

.divider{
  margin: 40px 0;
  padding: 0.5px;
  background-color: #ececec;
}