@import '../../theme/global-variables.scss';

.statistics-container {
    max-width: $max-width;
    margin: 0 auto;
    background-color: #fff;

    .statistics-wrapper {
        color: white;
        padding: 80px 150px;

        .title {
            font-size: 2.5rem;
            font-weight: 400;
            color: #232323;
        }

        p {
            color: #232323;
            margin: 20px 0;
        }

        .content {
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
            text-transform: capitalize;

            .statistics {
                width: 250px;

                .statistics-name {
                    margin-top: 20px;
                    font-size: 1.5rem;
                    color: #f37e21;
                    font-weight: 400;
                }

                .count {
                    font-size: 3rem;
                    color: #232323;
                }
            }
        }
    }
}

@media screen and (max-width: 845px) {
    .statistics-container {

        .statistics-wrapper {
            padding: 40px 80px;

            .title {
                font-size: 2.2rem;
            }

            .content {

                .statistics {

                    .statistics-name {
                        margin-top: 20px;
                        font-size: 1.3rem;
                    }

                    .count {
                        font-size: 2.3rem;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 645px) {
    .statistics-container {

        .statistics-wrapper {

            .title {
                font-size: 1.8rem;
            }
        }
    }
}

@media screen and (max-width: 426px) {
    .statistics-container {

        .statistics-wrapper {
            padding: 40px 50px;

            .title {
                font-size: 1.6rem;
            }

            .content {

                .statistics {

                    .statistics-name {
                        margin-top: 20px;
                        font-size: 1rem;
                    }

                    .count {
                        font-size: 2rem;
                    }
                }
            }
        }
    }
}