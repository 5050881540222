@import "../../theme/global-variables.scss";

.export-container {
    max-width: $max-width;
    margin: 0 auto;

    .export-wrapper {

        .export-details {
            display: flex;
            align-items: center;

            .image {
                height: 500px;
                width: 50%;
                margin: 60px 0;

                img {
                    height: 100%;
                    width: 100%;
                }
            }

            .export-content {
                width: 40%;
                display: flex;
                flex-direction: column;
                padding: 50px;

                .heading {
                    font-size: 2.5rem;
                    font-weight: 400;
                    margin-bottom: 10px;
                }

                p {
                    text-align: left;
                    margin-bottom: 10px;
                    line-height: 35px;
                }
            }
        }

        .export-images {
            width: 100%;
            position: relative;

            .heading {
                display: flex;
                align-items: center;
                justify-content: center;

                h3 {
                    font-size: 2.5rem;
                    font-weight: 400;
                }
            }

            .factory {
                display: flex;
                align-items: center;
                overflow: auto;

                img {
                    width: 100%;
                    height: 398px;
                }
            }
        }

        .map-container {
            padding: 30px;
            background-color: #4b9cd2;
            text-align: center;

            .title {
                color: white;
                font-size: 2.5rem;
            }

            .map-image {
                margin-top: 40px;
                width: 100%;

                img {
                    width: 80%;
                }
            }
        }

        .country-flags-container {
            padding: 20px;
            display: flex;
            justify-content: center;
            flex-wrap: wrap;

            .country-flags-row {
                margin: 15px 30px;
                width: 170px;

                ul li {
                    list-style: none;
                    padding-bottom: 10px;
                    display: flex;
                    align-items: center;
                }

                ul li span {
                    font-size: 17px;
                    font-weight: 500;
                    color: #fff;
                }
            }
        }
    }
}

@media screen and (max-width: 1024px) {
    .export-container {

        .export-wrapper {

            .export-details {
                display: flex;
                width: 100%;
                justify-content: center;

                .image {
                    display: none;
                }

                .export-content {
                    width: 80%;
                    padding: 30px;
                }
            }
        }
    }
}

@media screen and (max-width: 768px) {
    .export-container {

        .export-wrapper {

            .export-details {

                .export-content {
                    width: 70%;
                }
            }

            .map-container {
                padding: 40px 30px;

                .title {
                    font-size: 2rem;
                }
            }

            .country-flags-container {

                .country-flags-row {
                    margin: 15px 25px;
                }
            }
        }
    }
}

@media screen and (max-width: 426px) {
    .export-container {

        .export-wrapper {

            .export-details {

                .image {
                    display: none;
                }

                .export-content {
                    width: 80%;
                    padding: 20px;

                    .heading {
                        font-size: 1.5rem;
                    }
                }
            }

            .export-images {
    
                .heading {
    
                    h3 {
                        font-size: 1.5rem;
                    }
                }

                .factory{

                    img{
                        height: 408px;
                    }
                }
            }

            .map-container {

                .title {
                    font-size: 1.3rem;
                }
            }

            .country-flags-container {

                .country-flags-row {
                    width: 140px;
                    margin: 0px;

                    ul li span {
                        font-size: 13px;
                    }
                }
            }
        }
    }
}