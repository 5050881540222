@import '../../theme/global-variables.scss';

.enquiry-container {
    max-width: $max-width;
    margin: 0 auto;

    .enquiry-wrapper {
        padding: 30px;
        display: flex;
        flex-direction: column;
        align-items: center;
        background: #000;
        color: #fff;
        height: 100vh;
        overflow-x: auto;

        .heading {
            color: #f37e21;
            border-bottom: #fff 2px solid;
            margin-bottom: 30px;
        }

        .table {
            width: 100%;
            border: 2px solid #fff;

            th {
                border-bottom: 1px solid #fff;
                padding: 10px;
            }

            td {
                width: fit-content;
                text-align: center;
                padding: 10px;
            }

            td:last-child,
            th:last-child {
                width: 300px;
            }
        }
    }
}


@media screen and (max-width: 760px) {
    .enquiry-container {
    
        .enquiry-wrapper {
    
            .table {
                width: 100%;
                thead{
                    display: none;
                }
                tr{
                    border-bottom: 2px solid #ffff;
                    margin-bottom: 15px;
                }
                td {
                    padding-left: 40%;
                    text-align: start;
                    position: relative;
                    border-bottom: 1px solid #fff;
                    width: 58%;
                }
                tbody, tr, td {
                    display: block;
                }
    
                td:last-child,
                th:last-child {
                    width: unset;
                }

                td::before {
                    content: attr(data-label);
                    font-weight: bold;
                    position: absolute;
                    left: 0;
                    padding-left: 15px;
                }
            }
        }
    } 
}