@import "../../theme/global-variables.scss";

.sendMsg_container {
    max-width: $max-width;
    margin: 0 auto;
    padding: 80px 150px;

    h3 {
        margin-bottom: 50px;
        font-size: 2.5rem;
        font-weight: 400;
    }

    .sendMsg_wrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .image_container {
            width: 60%;
            height: 400px;
            background-image: url('../../assets/contactUsHomePage.jpg');
            background-repeat: no-repeat;
            background-size: cover;
            background-position: center right;

            // img {
            //     width: 100%;
            // }
        }

        .contact_us_form {
            width: 35%;

            .heading {
                margin-bottom: 10px;
            }

            .user_form {
                display: flex;
                flex-direction: column;

                textarea {
                    resize: none;
                    max-width: 100%;
                }

                .input_field {
                    margin-bottom: 20px;
                    padding: 15px;
                    border-radius: 4px;
                    width: 100%;
                    background-color: rgb(244, 242, 242);
                    border: none;
                    resize: none;
                    box-sizing: border-box;
                }

                .button {
                    padding: 15px;
                    color: white;
                    cursor: pointer;
                    border: none;
                    background-color: #f37e21;
                    border-radius: 25px;
                    font-weight: 600;
                    letter-spacing: 1px;
                }
            }
        }
    }
}

@media screen and (max-width: 1024px) {
    .sendMsg_container {
        padding: 80px 100px;
    
        h3 {
            margin-bottom: 40px;
            font-size: 2rem;
        }
    }
}

@media screen and (max-width: 821px) {
    .sendMsg_container {
        padding: 30px 50px;
    
        .sendMsg_wrapper {
            flex-direction: column;
            .image_container {
                width: 100%;
            }
    
            .contact_us_form {
                width: 100%;
                margin: 30px 0px;
            }
        }
    }
}

@media screen and (max-width: 496px) {
    .sendMsg_container {
        padding: 20px 35px;
    
        h3 {
            font-size: 1.5rem;
            margin-bottom: 20px;
        }
    }
}