@import '../../theme/global-variables.scss';

.about-container {
    max-width: $max-width;
    margin: 0 auto;

    .about-us-details {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        padding: 30px 150px;

        .about-mission-vision {
            width: 50%;
            text-align: left;

            .our-mission-vision {
                margin: 0px 0px 30px 0px;

                h3 {
                    color: #f37e21;
                    font-size: 3rem;
                    margin-bottom: 20px;
                }

                .mission-content {
                    font-size: 1.2rem;
                    margin-bottom: 20px;
                    line-height: 40px;
                }

                .detail {
                    color: #4b4a4a;
                    font-size: 1.2rem;
                }
            }
        }

        .images-wrapper {
            position: relative;
            width: 40%;
            // height: 350px;

            img {
                display: block;
                width: 100%;
            }

            .bg-image {
                display: block;
                height: 100%;
            }

            .fg-image {
                background-color: #fff;
                position: relative;
                top: -50px;
                left: 50px;
            }
        }

        .images {
            display: none;
        }
    }
}

@media screen and (max-width: 1024px) {
    .about-container {

        .about-us-details {
            padding: 30px 100px;
            flex-direction: column;

            .about-mission-vision {
                width: 100%;

                .our-mission-vision {

                    .mission-content {
                        font-size: 1.3rem;
                    }

                    .detail {
                        font-size: 1.3rem;
                    }
                }
            }

            .images-wrapper {
                display: none;
            }

            .images {
                display: block;
                width: 100%;

                img {
                    width: 100%;
                }

                .fg-image {
                    display: none;
                }
            }
        }
    }
}

@media screen and (max-width: 821px) {
    .about-container {

        .about-us-details {
            padding: 20px 50px;

            .about-mission-vision {

                .our-mission-vision {
                    h3 {
                        font-size: 2rem;
                    }

                    .mission-content {
                        font-size: 1.1rem;
                    }

                    .detail {
                        font-size: 1.1rem;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 496px) {
    .about-container {

        .about-us-details {
            padding: 0px 35px;

            .about-mission-vision {

                .our-mission-vision {
                    h3 {
                        font-size: 1.5rem;
                    }

                    .mission-content {
                        font-size: 1rem;
                    }

                    .detail {
                        font-size: 1rem;
                    }
                }
            }
        }
    }
}