@import '../../../theme/global-variables.scss';

.container{
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    height: 400px;
    max-width: $max-width;
    margin: 0 auto;

    .wrapper{
        background-color: rgba($color: #101211, $alpha: 0.6);
        height: 100%;
        position: relative;

        .header_detail{
            color: #ffff;
            position: absolute;
            top: 50%;
            left: 10%;
            transform: translate(0%, -50%);
            text-transform: capitalize;

            .description{
                font-size: 4rem;
                font-weight: 600;
            }

            .title{
                font-size: 1.2rem;
            }
        }
    }
}

@media screen and (max-width: 1100px) {
    
    .container{
    
        .wrapper{
    
            .header_detail{
    
                .description{
                    font-size: 3rem;
                }
            }
        }
    }
}

@media screen and (max-width: 768px) {
    
    .container{
        height: 300px;
    
        .wrapper{
    
            .header_detail{
                left: 50%;
                width: 70%;
                transform: translate(-50%, -50%);
    
                .description{
                    font-size: 2.5rem;
                }
    
                .title{
                    font-size: 1.2rem;
                }
            }
        }
    }
}

@media screen and (max-width: 496px) {
    
    .container{
    
        .wrapper{
    
            .header_detail{
                left: 50%;
                width: 80%;
                transform: translate(-50%, -50%);
    
                .description{
                    font-size: 1.5rem;
                    font-weight: 500;
                }
    
                .title{
                    font-size: 1rem;
                }
            }
        }
    }
}